/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { DeviceBoDtoNBK } from '../model/models';
import { DeviceDisplayRequestParamNBK } from '../model/models';
import { DeviceDtoNBK } from '../model/models';
import { DeviceParametersDtoNBK } from '../model/models';
import { DeviceSettingsDtoNBK } from '../model/models';
import { DeviceTechnicianAssociationDtoNBK } from '../model/models';
import { IdsRequestNBK } from '../model/models';
import { LightResourceDtoNBK } from '../model/models';
import { PairTechniacianDevicesRequestNBK } from '../model/models';
import { ResultSetDeviceBoDtoNBK } from '../model/models';
import { ResultSetDeviceDisplayDtoNBK } from '../model/models';
import { ResultSetDeviceDtoNBK } from '../model/models';
import { ResultSetDeviceTechnicianAssociationDtoNBK } from '../model/models';
import { UpdateNameRequestNBK } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  protected basePath = 'http://localhost/cloud/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Device association
   * @param serialNumber
   * @param secureCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addCurrentUserToDevice(
    serialNumber: string,
    secureCode: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public addCurrentUserToDevice(
    serialNumber: string,
    secureCode: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public addCurrentUserToDevice(
    serialNumber: string,
    secureCode: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public addCurrentUserToDevice(
    serialNumber: string,
    secureCode: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (serialNumber === null || serialNumber === undefined) {
      throw new Error(
        'Required parameter serialNumber was null or undefined when calling addCurrentUserToDevice.'
      );
    }
    if (secureCode === null || secureCode === undefined) {
      throw new Error(
        'Required parameter secureCode was null or undefined when calling addCurrentUserToDevice.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (serialNumber !== undefined) {
      formParams =
        (formParams.append('serialNumber', <any>serialNumber) as any) ||
        formParams;
    }
    if (secureCode !== undefined) {
      formParams =
        (formParams.append('secureCode', <any>secureCode) as any) || formParams;
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/bo/device/pair-user`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Remove given entities.
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public bulkDeleteDeviceTechnician(
    body?: IdsRequestNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public bulkDeleteDeviceTechnician(
    body?: IdsRequestNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public bulkDeleteDeviceTechnician(
    body?: IdsRequestNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public bulkDeleteDeviceTechnician(
    body?: IdsRequestNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/bo/device-techinician/bulk-delete`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Remove technician device association
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDeviceTechnician(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public deleteDeviceTechnician(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public deleteDeviceTechnician(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public deleteDeviceTechnician(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteDeviceTechnician.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/bo/device-techinician/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Generate device secure code for device association
   * @param serialNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public generateSecureCode(
    serialNumber: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public generateSecureCode(
    serialNumber: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public generateSecureCode(
    serialNumber: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public generateSecureCode(
    serialNumber: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (serialNumber === null || serialNumber === undefined) {
      throw new Error(
        'Required parameter serialNumber was null or undefined when calling generateSecureCode.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(serialNumber))}/secure-code`,
      null,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get All Devices associated to logged user
   * @param acceptLanguage
   * @param $top Max number item to get
   * @param $skip How many record skip before the first item
   * @param $count True to get also the total number of item the server has
   * @param $orderby Order by the result
   * @param $filter Filter the result
   * @param $search String to search in items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllDevices(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetDeviceDtoNBK>;
  public getAllDevices(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetDeviceDtoNBK>>;
  public getAllDevices(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetDeviceDtoNBK>>;
  public getAllDevices(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if ($top !== undefined && $top !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$top,
        '$top'
      );
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$skip,
        '$skip'
      );
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$count,
        '$count'
      );
    }
    if ($orderby !== undefined && $orderby !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$orderby,
        '$orderby'
      );
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$filter,
        '$filter'
      );
    }
    if ($search !== undefined && $search !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$search,
        '$search'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<ResultSetDeviceDtoNBK>(
      `${this.configuration.basePath}/bo/device`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get all devices for the logged service user.
   * @param acceptLanguage
   * @param $top Max number item to get
   * @param $skip How many record skip before the first item
   * @param $count True to get also the total number of item the server has
   * @param $orderby Order by the result
   * @param $filter Filter the result
   * @param $search String to search in items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllDevicesForServiceUser(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetDeviceBoDtoNBK>;
  public getAllDevicesForServiceUser(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetDeviceBoDtoNBK>>;
  public getAllDevicesForServiceUser(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetDeviceBoDtoNBK>>;
  public getAllDevicesForServiceUser(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if ($top !== undefined && $top !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$top,
        '$top'
      );
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$skip,
        '$skip'
      );
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$count,
        '$count'
      );
    }
    if ($orderby !== undefined && $orderby !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$orderby,
        '$orderby'
      );
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$filter,
        '$filter'
      );
    }
    if ($search !== undefined && $search !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$search,
        '$search'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<ResultSetDeviceBoDtoNBK>(
      `${this.configuration.basePath}/bo/device/for-service`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get All Devices associated to logged user with his current displays. Be careful to call it without pagination!
   * @param acceptLanguage
   * @param $top Max number item to get
   * @param $skip How many record skip before the first item
   * @param $count True to get also the total number of item the server has
   * @param $orderby Order by the result
   * @param $filter Filter the result
   * @param $search String to search in items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllDevicesWIthDisplays(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetDeviceBoDtoNBK>;
  public getAllDevicesWIthDisplays(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetDeviceBoDtoNBK>>;
  public getAllDevicesWIthDisplays(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetDeviceBoDtoNBK>>;
  public getAllDevicesWIthDisplays(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if ($top !== undefined && $top !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$top,
        '$top'
      );
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$skip,
        '$skip'
      );
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$count,
        '$count'
      );
    }
    if ($orderby !== undefined && $orderby !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$orderby,
        '$orderby'
      );
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$filter,
        '$filter'
      );
    }
    if ($search !== undefined && $search !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$search,
        '$search'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<ResultSetDeviceBoDtoNBK>(
      `${this.configuration.basePath}/bo/device/with-displays`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of devices related to the given display
   * @param displayId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getByDisplay(
    displayId: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<Array<LightResourceDtoNBK>>;
  public getByDisplay(
    displayId: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<Array<LightResourceDtoNBK>>>;
  public getByDisplay(
    displayId: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<Array<LightResourceDtoNBK>>>;
  public getByDisplay(
    displayId: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (displayId === null || displayId === undefined) {
      throw new Error(
        'Required parameter displayId was null or undefined when calling getByDisplay.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (displayId !== undefined && displayId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>displayId,
        'displayId'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<LightResourceDtoNBK>>(
      `${this.configuration.basePath}/bo/device/by-display`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get single Device by Id
   * @param id
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceById(
    id: number,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceDtoNBK>;
  public getDeviceById(
    id: number,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceDtoNBK>>;
  public getDeviceById(
    id: number,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceDtoNBK>>;
  public getDeviceById(
    id: number,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getDeviceById.'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<DeviceDtoNBK>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get single Device by serial
   * @param serial
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceBySerial(
    serial: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceBoDtoNBK>;
  public getDeviceBySerial(
    serial: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceBoDtoNBK>>;
  public getDeviceBySerial(
    serial: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceBoDtoNBK>>;
  public getDeviceBySerial(
    serial: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (serial === null || serial === undefined) {
      throw new Error(
        'Required parameter serial was null or undefined when calling getDeviceBySerial.'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<DeviceBoDtoNBK>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(serial))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get Device param By Id
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceParams(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceParametersDtoNBK>;
  public getDeviceParams(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceParametersDtoNBK>>;
  public getDeviceParams(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceParametersDtoNBK>>;
  public getDeviceParams(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getDeviceParams.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<DeviceParametersDtoNBK>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}/params`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get Device settings By Id
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceSettings(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceSettingsDtoNBK>;
  public getDeviceSettings(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceSettingsDtoNBK>>;
  public getDeviceSettings(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceSettingsDtoNBK>>;
  public getDeviceSettings(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getDeviceSettings.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<DeviceSettingsDtoNBK>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}/settings`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get all devices tecnichian associations.
   * @param acceptLanguage
   * @param $top Max number item to get
   * @param $skip How many record skip before the first item
   * @param $count True to get also the total number of item the server has
   * @param $orderby Order by the result
   * @param $filter Filter the result
   * @param $search String to search in items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceTechnicians(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetDeviceTechnicianAssociationDtoNBK>;
  public getDeviceTechnicians(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetDeviceTechnicianAssociationDtoNBK>>;
  public getDeviceTechnicians(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetDeviceTechnicianAssociationDtoNBK>>;
  public getDeviceTechnicians(
    acceptLanguage?: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    $orderby?: string,
    $filter?: string,
    $search?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if ($top !== undefined && $top !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$top,
        '$top'
      );
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$skip,
        '$skip'
      );
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$count,
        '$count'
      );
    }
    if ($orderby !== undefined && $orderby !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$orderby,
        '$orderby'
      );
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$filter,
        '$filter'
      );
    }
    if ($search !== undefined && $search !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>$search,
        '$search'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<ResultSetDeviceTechnicianAssociationDtoNBK>(
      `${this.configuration.basePath}/bo/device-techinician`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get all Devices associated to logged user filtered by model and display cookingMode
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserDeviceDisplay(
    body?: DeviceDisplayRequestParamNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<ResultSetDeviceDisplayDtoNBK>;
  public getUserDeviceDisplay(
    body?: DeviceDisplayRequestParamNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<ResultSetDeviceDisplayDtoNBK>>;
  public getUserDeviceDisplay(
    body?: DeviceDisplayRequestParamNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<ResultSetDeviceDisplayDtoNBK>>;
  public getUserDeviceDisplay(
    body?: DeviceDisplayRequestParamNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.post<ResultSetDeviceDisplayDtoNBK>(
      `${this.configuration.basePath}/bo/device/device-display`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Pare techinicians with devices (create or update associations).
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pairTechniciansDevices(
    body?: PairTechniacianDevicesRequestNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<Array<DeviceTechnicianAssociationDtoNBK>>;
  public pairTechniciansDevices(
    body?: PairTechniacianDevicesRequestNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<Array<DeviceTechnicianAssociationDtoNBK>>>;
  public pairTechniciansDevices(
    body?: PairTechniacianDevicesRequestNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<Array<DeviceTechnicianAssociationDtoNBK>>>;
  public pairTechniciansDevices(
    body?: PairTechniacianDevicesRequestNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.post<Array<DeviceTechnicianAssociationDtoNBK>>(
      `${this.configuration.basePath}/bo/device-techinician`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Device disassociation from user
   * @param serialNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeCurrentUserFromDevice(
    serialNumber: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public removeCurrentUserFromDevice(
    serialNumber: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public removeCurrentUserFromDevice(
    serialNumber: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public removeCurrentUserFromDevice(
    serialNumber: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (serialNumber === null || serialNumber === undefined) {
      throw new Error(
        'Required parameter serialNumber was null or undefined when calling removeCurrentUserFromDevice.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (serialNumber !== undefined) {
      formParams =
        (formParams.append('serialNumber', <any>serialNumber) as any) ||
        formParams;
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/bo/device/unpair-user`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Rename device
   * @param id
   * @param name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public renameDevice(
    id: number,
    name: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceDtoNBK>;
  public renameDevice(
    id: number,
    name: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceDtoNBK>>;
  public renameDevice(
    id: number,
    name: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceDtoNBK>>;
  public renameDevice(
    id: number,
    name: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling renameDevice.'
      );
    }
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling renameDevice.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (name !== undefined) {
      formParams = (formParams.append('name', <any>name) as any) || formParams;
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<DeviceDtoNBK>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}/rename`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Reset Device counters
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resetDeviceCounters(
    id: number,
    body?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public resetDeviceCounters(
    id: number,
    body?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public resetDeviceCounters(
    id: number,
    body?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public resetDeviceCounters(
    id: number,
    body?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling resetDeviceCounters.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}/counters`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set Device param
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setDeviceParams(
    id: number,
    body?: DeviceParametersDtoNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public setDeviceParams(
    id: number,
    body?: DeviceParametersDtoNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public setDeviceParams(
    id: number,
    body?: DeviceParametersDtoNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public setDeviceParams(
    id: number,
    body?: DeviceParametersDtoNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling setDeviceParams.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}/params`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set device settings
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setDeviceSettings(
    id: number,
    body?: DeviceSettingsDtoNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public setDeviceSettings(
    id: number,
    body?: DeviceSettingsDtoNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public setDeviceSettings(
    id: number,
    body?: DeviceSettingsDtoNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public setDeviceSettings(
    id: number,
    body?: DeviceSettingsDtoNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling setDeviceSettings.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/bo/device/${encodeURIComponent(String(id))}/settings`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update technician device association duration
   * @param id
   * @param fromDate
   * @param toDate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTechincianDateRange(
    id: number,
    fromDate: string,
    toDate?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceTechnicianAssociationDtoNBK>;
  public updateTechincianDateRange(
    id: number,
    fromDate: string,
    toDate?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceTechnicianAssociationDtoNBK>>;
  public updateTechincianDateRange(
    id: number,
    fromDate: string,
    toDate?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceTechnicianAssociationDtoNBK>>;
  public updateTechincianDateRange(
    id: number,
    fromDate: string,
    toDate?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateTechincianDateRange.'
      );
    }
    if (fromDate === null || fromDate === undefined) {
      throw new Error(
        'Required parameter fromDate was null or undefined when calling updateTechincianDateRange.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>fromDate,
        'fromDate'
      );
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>toDate,
        'toDate'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.patch<DeviceTechnicianAssociationDtoNBK>(
      `${this.configuration.basePath}/bo/device-techinician/${encodeURIComponent(String(id))}/validity`,
      null,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update technician device association duration
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTechnicianDeviceName(
    id: number,
    body?: UpdateNameRequestNBK,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<DeviceTechnicianAssociationDtoNBK>;
  public updateTechnicianDeviceName(
    id: number,
    body?: UpdateNameRequestNBK,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<DeviceTechnicianAssociationDtoNBK>>;
  public updateTechnicianDeviceName(
    id: number,
    body?: UpdateNameRequestNBK,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<DeviceTechnicianAssociationDtoNBK>>;
  public updateTechnicianDeviceName(
    id: number,
    body?: UpdateNameRequestNBK,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateTechnicianDeviceName.'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.patch<DeviceTechnicianAssociationDtoNBK>(
      `${this.configuration.basePath}/bo/device-techinician/${encodeURIComponent(String(id))}/rename`,
      body,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
