var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import orderBy from 'lodash.orderby';
import { FiltrableDataSource } from '../FiltrableDataSource';
import { filterItems } from './filterFunctions';
var emptyItemsError = 'Error: initial data not provided, use setItems to set initial data';
var ClientFiltrableDataSource = /** @class */function (_super) {
  __extends(ClientFiltrableDataSource, _super);
  function ClientFiltrableDataSource() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Object.defineProperty(ClientFiltrableDataSource.prototype, "items", {
    get: function () {
      return this._sortedItems || this._filteredItems || this._items;
    },
    enumerable: true,
    configurable: true
  });
  ClientFiltrableDataSource.prototype.setItems = function (items) {
    this._items = items;
    this.filterData();
  };
  ClientFiltrableDataSource.prototype.loadData = function () {
    if (this._items == null) {
      throw new Error(emptyItemsError);
    }
    var firstItem = this.page * this.limit;
    var lastItem = firstItem + this.limit;
    this.currentItems = this.items.slice(firstItem, lastItem);
  };
  ClientFiltrableDataSource.prototype.setCustomFilter = function (filterFn) {
    this.filters.custom = filterFn;
  };
  ClientFiltrableDataSource.prototype.removeCustomFilter = function () {
    this.filters.custom = undefined;
  };
  ClientFiltrableDataSource.prototype.filterData = function () {
    if (this._items == null) {
      throw new Error(emptyItemsError);
    }
    this._filteredItems = filterItems(this._items, this._filters);
    this._totalItems = this._filteredItems ? this._filteredItems.length : this._items.length;
    this.sortData();
    this.loadData();
  };
  ClientFiltrableDataSource.prototype.sortData = function () {
    if (this._items == null) {
      throw new Error(emptyItemsError);
    }
    if (this._sortBy) {
      var items = this._filteredItems || this._items;
      this._sortedItems = orderBy(items, this._sortBy.prop, this._sortBy.dir);
    } else {
      this._sortedItems = undefined;
    }
    this.loadData();
  };
  return ClientFiltrableDataSource;
}(FiltrableDataSource);
export { ClientFiltrableDataSource };