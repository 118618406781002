/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceConfigurationDtoNBK {
  keyboard?: string;
  language?: string;
  syncMode?: DeviceConfigurationDtoNBK.SyncModeEnum;
  tecnicoService?: string;
  tecnicoDistributore?: string;
  lastConfigUpdate?: string;
}
export namespace DeviceConfigurationDtoNBK {
  export type SyncModeEnum = 'MONODIRECTIONAL' | 'BIDIRECTIONAL' | 'MANUAL';
  export const SyncModeEnum = {
    Monodirectional: 'MONODIRECTIONAL' as SyncModeEnum,
    Bidirectional: 'BIDIRECTIONAL' as SyncModeEnum,
    Manual: 'MANUAL' as SyncModeEnum
  };
}
