import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
export type TwoBtnModalState = Pick<
  TwoBtnModalComponent,
  | 'title'
  | 'message'
  | 'confirmBtnText'
  | 'confirmBtnClass'
  | 'cancelBtnText'
  | 'cancelBtnClass'
  | 'confirm$'
  | 'preventClose'
>;

@Component({
  selector: 'app-two-btn-modal',
  templateUrl: './two-btn-modal.component.html',
  styleUrls: ['./two-btn-modal.component.scss']
})
export class TwoBtnModalComponent implements OnInit {
  title: string;
  message: string;
  confirmBtnText?: string;
  confirmBtnClass?: string;
  cancelBtnText?: string;
  cancelBtnClass?: string;
  confirm$?: Observable<unknown>;
  preventClose?: boolean;

  isHtml = false
  isLoading = false;

  @Output() modalResult = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal, private ts: TranslateService) {
    this.confirmBtnText = this.confirmBtnText
      ? this.confirmBtnText
      : ts.instant('GLOBAL.OK');
    this.confirmBtnClass = this.confirmBtnClass
      ? this.confirmBtnClass
      : 'button-primary';
    this.cancelBtnText = this.cancelBtnText
      ? this.cancelBtnText
      : ts.instant('GLOBAL.CANCEL');
    this.cancelBtnClass = this.cancelBtnClass
      ? this.cancelBtnClass
      : 'button-secondary';

    this.isHtml = this.containsHtml(this.message);
    
  }

  ngOnInit(): void {}

  close() {
    this.modalResult.emit(false);
    this.activeModal.close();
  }

  confirm() {
    this.isLoading = true;
    if (this.confirm$) {
      this.confirm$.subscribe(() => {
        this.isLoading = false;
        this.modalResult.emit(true);
        this.activeModal.close();
      });
    } else {
      this.isLoading = false;
      this.modalResult.emit(true);
      this.activeModal.close();
    }
  }

  containsHtml(str: string): boolean {
    const div = document.createElement('div');
    div.innerHTML = str;
    return div.childNodes.length > 0;
  }

}