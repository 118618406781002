<div class="modal-title px-3 pt-3">
  <h2>{{ title }}</h2>
</div>
<div *ngIf="!isHtml" class="modal-body p-3">
  {{ message }}
</div>
<div *ngIf="isHtml" class="modal-body p-3" [innerHTML]="message"></div>
<div class="modal-footer">
  <button type="button" ngClass="{{ cancelBtnClass }} mr-2" (click)="close()">
    {{ cancelBtnText }}
  </button>

  <app-loading-button
    buttonType="button"
    [buttonClass]="confirmBtnClass"
    [isLoading]="isLoading"
    (click)="confirm()"
  >
    {{ confirmBtnText }}</app-loading-button
  >
</div>
