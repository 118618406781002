import { deepFilter } from './deepFilter';
var clientFilters = {
  equal: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v === value;
    }, acc);
  },
  notEqual: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v !== value;
    }, acc);
  },
  greaterThan: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v > value;
    }, acc);
  },
  greaterThanOrEqual: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v >= value;
    }, acc);
  },
  lessThan: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v < value;
    }, acc);
  },
  lessThanOrEqual: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v <= value;
    }, acc);
  },
  contains: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return v.toLocaleLowerCase().includes(value.toLocaleLowerCase());
    }, acc);
  },
  in: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return value.indexOf(v) !== -1;
    }, acc);
  },
  notIn: function (value, field, acc) {
    return deepFilter(field, function (v) {
      return value.indexOf(v) === -1;
    }, acc);
  },
  and: function (value, field, acc) {
    acc = applyFilter(value[0], field, acc);
    acc = applyFilter(value[1], field, acc);
    return acc;
  },
  or: function (value, field, acc) {
    var val1 = applyFilter(value[0], field, acc);
    var val2 = applyFilter(value[1], field, acc);
    return val1.concat(val2);
  },
  not: function (value, field, acc) {
    var original = applyFilter(value, field, acc);
    return acc.filter(function (v) {
      return original.indexOf(v) === -1;
    });
  }
};
function applyFilter(filter, field, arr) {
  var filterFn = clientFilters[filter.operator];
  return filterFn(filter.value, field, arr);
}
function applyFieldFilters(items, filters) {
  var fields = Object.keys(filters);
  if (fields.length === 0) {
    return undefined;
  }
  return fields.reduce(function (arr, field) {
    return applyFilter(filters[field], field, arr);
  }, items);
}
export function filterItems(items, filters) {
  var filteredFields = applyFieldFilters(items, filters.fields);
  if (filters.custom === undefined) {
    return filteredFields;
  }
  if (filteredFields) {
    return filteredFields.filter(filters.custom);
  } else {
    return items.filter(filters.custom);
  }
}