import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessoryService } from './api/accessory.service';
import { AllergenService } from './api/allergen.service';
import { CompanyService } from './api/company.service';
import { DashboardService } from './api/dashboard.service';
import { DeviceService } from './api/device.service';
import { DisplayService } from './api/display.service';
import { FolderService } from './api/folder.service';
import { ImageService } from './api/image.service';
import { IngredientService } from './api/ingredient.service';
import { IngredientTypeService } from './api/ingredientType.service';
import { IotCallbackService } from './api/iotCallback.service';
import { MenuService } from './api/menu.service';
import { MyNabooService } from './api/myNaboo.service';
import { NationService } from './api/nation.service';
import { RecipeService } from './api/recipe.service';
import { RecipeTypeService } from './api/recipeType.service';
import { TagService } from './api/tag.service';
import { TelemetryService } from './api/telemetry.service';
import { UserService } from './api/user.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: []
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
