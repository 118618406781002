import { PhaseBoDtoNBK, RecipeBoDtoNBK } from '../api/nbk';

export type PhaseBoDtoNBKComplete = PhaseBoDtoNBK & { group: number };

export type NeoCookingType = 'LEAVENING' | 'SHOCK_FREEZING' | 'BLAST_CHILLING' | 'SLOW_COOKING';

export type NeoWithSpilloneCookingType = 'SHOCK_FREEZING' | 'BLAST_CHILLING' | 'SLOW_COOKING';
export type NabooWithSpilloneCookingType = 'CONVECTION' | 'STEAM' | 'COMBO';

export type PhaseControlTypeEnum =
  | 'TEMPERATURE'
  | 'DELTA_TEMPERATURE'
  | 'TIME'
  | 'SPILLONE'
  | 'AUTOCLIMATE'
  | 'VAPOR_TYPE'
  | 'WAVE_POWER'
  | 'FAN';
export const PhaseControlTypeEnum = {
  Temperature: 'TEMPERATURE' as PhaseControlTypeEnum,
  DeltaTemperature: 'DELTA_TEMP' as PhaseControlTypeEnum,
  Time: 'TIME' as PhaseControlTypeEnum,
  Spillone: 'SPILLONE' as PhaseControlTypeEnum,
  Autoclimate: 'AUTOCLIMATE' as PhaseControlTypeEnum,
  VaporType: 'VAPOR_TYPE' as PhaseControlTypeEnum,
  WavePower: 'WAVE_POWER' as PhaseControlTypeEnum,
  Fan: 'FAN' as PhaseControlTypeEnum
};

export type PhaseCommonSetting = 'PREHEAT' | 'HOLDING' | 'AIRVENT' | 'FINISHING' | 'DRYING';
export type PhaseSingleSetting = 'ACTIONS' | 'SOFT_DRY' | 'MOISTURIZING' | 'AROMA' | 'SMOKE';

export const SETTING = {
  COMBI: {
    PHASE_COMMON_SETTINGS: ['PREHEAT', 'HOLDING'],
    PHASE_SINGLE_SETTINGS: ['ACTIONS'],
    PH: {
      MAX_VALUE_PREHEAT: 300,
      MIN_VALUE_PREHEAT: 36,
      PERCENTAGE_MIN_VALUE_PREHEAT: 0.2
    },
    TEMP: {
      MIN_VALUE: 30,
      MAX_VALUE: 300
    }
  },
  HSO: {
    PHASE_COMMON_SETTINGS: ['PREHEAT', 'HOLDING'],
    PHASE_SINGLE_SETTINGS: ['ACTIONS'],
    PH: {
      MAX_VALUE_PREHEAT: 300,
      MIN_VALUE_PREHEAT: 100,
      DEFAULT_VALUE: 250,
      PERCENTAGE_MIN_VALUE_PREHEAT: 1
    },
    TEMP: {
      MIN_VALUE: 30,
      MAX_VALUE: 300
    }
  },
  NABOO: {
    PHASE_COMMON_SETTINGS: ['PREHEAT', 'HOLDING', 'AIRVENT', 'FINISHING'],
    PHASE_SINGLE_SETTINGS: ['ACTIONS', 'SOFT_DRY', 'MOISTURIZING', 'AROMA', 'SMOKE'],
    PH: {
      MAX_VALUE_PREHEAT: 320,
      MIN_VALUE_PREHEAT: 30,
      DEFAULT_VALUE: 192,
      PERCENTAGE_MIN_VALUE_PREHEAT: 0.2
    },
    TEMP: {
      MIN_VALUE: 30,
      MAX_VALUE: 300
    },
    SPILLONE: {
      CONVECTION: {
        MIN_VALUE: 1,
        MAX_VALUE: 150
      },
      STEAM: {
        MIN_VALUE: 1,
        MAX_VALUE: 125
      },
      COMBO: {
        MIN_VALUE: 1,
        MAX_VALUE: 150
      }
    },
    DELTA: {
      CONVECTION: {
        MIN_VALUE: 5,
        MAX_VALUE: 150
      },
      STEAM: {
        MIN_VALUE: 30,
        MAX_VALUE: 130
      },
      COMBO: {
        MIN_VALUE: 5,
        MAX_VALUE: 150
      }
    }
  },
  NEO: {
    PHASE_COMMON_SETTINGS: ['HOLDING', 'DRYING'],
    PHASE_SINGLE_SETTINGS: [],
    PH: {
      MAX_VALUE_PREHEAT: 320,
      MIN_VALUE_PREHEAT: 30,
      DEFAULT_VALUE: 0,
      PERCENTAGE_MIN_VALUE_PREHEAT: 0
    },
    TEMP: {
      LEAVENING: {
        MIN_VALUE: -40,
        MAX_VALUE: 25
      },
      SHOCK_FREEZING: {
        MIN_VALUE: -40,
        MAX_VALUE: 45
      },
      BLAST_CHILLING: {
        MIN_VALUE: -20,
        MAX_VALUE: 45
      },
      SLOW_COOKING: {
        MIN_VALUE: 22,
        MAX_VALUE: 85
      }
    },
    SPILLONE: {
      SHOCK_FREEZING: {
        MIN_VALUE: -38,
        MAX_VALUE: 85
      },
      BLAST_CHILLING: {
        MIN_VALUE: -18,
        MAX_VALUE: 85
      },
      SLOW_COOKING: {
        MIN_VALUE: 20,
        MAX_VALUE: 78
      }
    }
  }
}

export const TEMP_TRESHOLD_NABOO = 260;
export const TURBO_VAPOR_TYPE_TRESHOLD = 100;
export const EC0_VAPOR_TYPE_TRESHOLD = 90;

export const MAINTENANCE_CONFIG = {
  MAX_VALUE: 80,
  MIN_VALUE: 30
};

export const MICROWAVE_SETUP = {};
export const TIMER_SETUP = {};
export const FAN_SETUP = {};

export type TTemperatureUnit = 'C' | 'F';

export interface IConfigSliderCookingStep {
  ceil?: number;
  floor?: number;
  step?: number;
  time?: boolean;
  firstStep?: number;
  minLimit?: number;
  maxLimit?: number;
}

export const convertToFahrenheit = (celsius: number): number => {
  return parseInt(((celsius * 9) / 5 + 32).toFixed(2));
};

export const convertToCelsius = (farhenheit: number): number => {
  return parseInt((((farhenheit - 32) * 5) / 9).toFixed(2));
};

export const convertRecipeToFahrenheit = (recipe: RecipeBoDtoNBK) => {
  recipe.preheat && recipe.preheat.temperature
    ? (recipe.preheat.temperature = convertToFahrenheit(
        recipe.preheat.temperature
      ))
    : null;

  recipe.holding && recipe.holding.temperature
    ? (recipe.holding.temperature = convertToFahrenheit(
        recipe.holding.temperature
      ))
    : null;

  recipe.phases?.forEach((phase) => {
    phase && phase.temperature
      ? (phase.temperature = convertToFahrenheit(phase.temperature))
      : null;
  });
};

export const convertRecipeToCelsius = (recipe: RecipeBoDtoNBK) => {
  recipe.preheat && recipe.preheat.temperature
    ? (recipe.preheat.temperature = convertToCelsius(
        recipe.preheat.temperature
      ))
    : null;

  recipe.holding && recipe.holding.temperature
    ? (recipe.holding.temperature = convertToCelsius(
        recipe.holding.temperature
      ))
    : null;

  recipe.phases?.forEach((phase) => {
    phase && phase.temperature
      ? (phase.temperature = convertToCelsius(phase.temperature))
      : null;
  });
};

export const preheatConfiguration = (
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
  tempUnit: TTemperatureUnit
) => {
  return {
    ceil: getTemperatureValue(SETTING[cookingMode].PH.MAX_VALUE_PREHEAT, tempUnit),
    floor: getTemperatureValue(SETTING[cookingMode].PH.MIN_VALUE_PREHEAT, tempUnit)
  };

};

export const temperatureConfiguration = (cookingMode: RecipeBoDtoNBK.CookingModeEnum, 
  tempUnit: TTemperatureUnit, cookingType?: NeoCookingType) => {
  switch (cookingMode) {
    case 'COMBI':
    case 'HSO':
    case 'NABOO':
      return {
        ceil: getTemperatureValue(SETTING[cookingMode].TEMP.MAX_VALUE, tempUnit),
        floor: getTemperatureValue(SETTING[cookingMode].TEMP.MIN_VALUE, tempUnit),
        minLimit: getTemperatureValue(SETTING[cookingMode].TEMP.MIN_VALUE, tempUnit)
      };
    case 'NEO':
      return {
        ceil: getTemperatureValue(SETTING[cookingMode].TEMP[cookingType!].MAX_VALUE, tempUnit),
        floor: getTemperatureValue(SETTING[cookingMode].TEMP[cookingType!].MIN_VALUE, tempUnit),
        minLimit: getTemperatureValue(SETTING[cookingMode].TEMP[cookingType!].MIN_VALUE, tempUnit)
      };
  }

};

export const spilloneConfiguration = (
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
  tempUnit: TTemperatureUnit,
  cookingType?: NabooWithSpilloneCookingType | NeoWithSpilloneCookingType
) => {
  switch (cookingMode) {
    case 'COMBI':
    case 'HSO':
      return undefined;
    case 'NABOO':
      return {
        ceil: getTemperatureValue(SETTING.NABOO.SPILLONE[cookingType as NabooWithSpilloneCookingType].MAX_VALUE, tempUnit),
        floor: getTemperatureValue(SETTING.NABOO.SPILLONE[cookingType as NabooWithSpilloneCookingType].MIN_VALUE, tempUnit),
        minLimit: getTemperatureValue(SETTING.NABOO.SPILLONE[cookingType as NabooWithSpilloneCookingType].MIN_VALUE, tempUnit)
      };
    case 'NEO':
      return {
        ceil: getTemperatureValue(SETTING.NEO.SPILLONE[cookingType as NeoWithSpilloneCookingType].MAX_VALUE, tempUnit),
        floor: getTemperatureValue(SETTING.NEO.SPILLONE[cookingType as NeoWithSpilloneCookingType].MIN_VALUE, tempUnit),
        minLimit: getTemperatureValue(SETTING.NEO.SPILLONE[cookingType as NeoWithSpilloneCookingType].MIN_VALUE, tempUnit)
      };
  }
};

export const deltaTempConfiguration = (
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
  tempUnit: TTemperatureUnit,
  cookingType?: NabooWithSpilloneCookingType | NeoWithSpilloneCookingType
) => {
  switch (cookingMode) {
    case 'COMBI':
    case 'HSO':
    case 'NEO':
      return undefined;
    case 'NABOO':
      return {
        ceil: getTemperatureValue(SETTING.NABOO.DELTA[cookingType as NabooWithSpilloneCookingType].MAX_VALUE, tempUnit),
        floor: getTemperatureValue(SETTING.NABOO.DELTA[cookingType as NabooWithSpilloneCookingType].MIN_VALUE, tempUnit),
        minLimit: getTemperatureValue(SETTING.NABOO.DELTA[cookingType as NabooWithSpilloneCookingType].MIN_VALUE, tempUnit)
      };
  }
};

export const phaseCommonSettings = (
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
) => {
  return SETTING[cookingMode].PHASE_COMMON_SETTINGS;
};

export const phaseSingleSettings = (
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
) => {
  return SETTING[cookingMode].PHASE_SINGLE_SETTINGS as string [];
};

export const maintenanceConfiguration = (tempUnit: TTemperatureUnit) => {
  return {
    ceil: getTemperatureValue(MAINTENANCE_CONFIG.MAX_VALUE, tempUnit),
    floor: getTemperatureValue(MAINTENANCE_CONFIG.MIN_VALUE, tempUnit),
    minLimit: getTemperatureValue(MAINTENANCE_CONFIG.MIN_VALUE, tempUnit)
  };
};

const getTemperatureValue = (value: number, tempUnit: TTemperatureUnit) => {
  return tempUnit === 'C'
      ? value
      : convertToFahrenheit(value);
}
