import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectableItem } from 'src/app/core/device.utils';

export type OptionsModalState = Pick<
OptionsModalComponent,
  | 'title'
  | 'message'
  | 'options'
  | 'preventClose'
>;

@Component({
  selector: 'app-options-modal',
  templateUrl: './options-modal.component.html',
  styleUrls: ['./options-modal.component.scss']
})
export class OptionsModalComponent implements OnInit {
  title: string;
  message: string;
  preventClose?: boolean;
  options?: SelectableItem[];

  @Output() modalResult = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal, private ts: TranslateService) { }

  ngOnInit(): void { }


  onChosen(value: any ) {
    this.modalResult.emit(value);
    this.activeModal.close();
  }


}