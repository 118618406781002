<div class="modal-title px-3 pt-3">
  <h2>{{ title }}</h2>
</div>
<div class="modal-body p-3">
  <div id="message">
    {{ message }}
  </div>
  <div id="options" class="mt-3 ms-3" >
    <div *ngFor="let option of options" class="form-check">
      <input
        type="radio"
        class="form-check-input"
        [id]="option.id"
        [value]="option.id"
        (click)="onChosen(option.id)">
      <label class="form-check-label" [for]="option.id">{{ option.name | translate }}</label>
    </div>
  </div>
</div>


