import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FolderBoDtoNBK, FolderService, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { disableTooltip, KEY_LANGUAGE_STORAGE, upperCaseTooltip } from 'src/app/core/utils';
import { MyDisplayService } from 'src/app/my-display/my-display.service';
import { RecipeFolderModalComponent } from './recipe-folder-modal/recipe-folder-modal.component';

@Component({
  selector: 'app-recipe-folder',
  templateUrl: './recipe-folder.component.html',
  styleUrls: ['./recipe-folder.component.scss']
})
export class RecipeFolderComponent implements OnInit, AfterContentChecked {
  @Input() folder: FolderBoDtoNBK;
  @Output() onRecipeDeleted: EventEmitter<RecipeBoDtoNBK> = new EventEmitter();
  @Output() onFolderDeleted: EventEmitter<FolderBoDtoNBK> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private ts: TranslateService,
    private toastrService: ToastrService,
    private folderService: FolderService,
    private md: MyDisplayService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  openFolderDetail() {
    const modalRef = this.modalService.open(RecipeFolderModalComponent, {
      modalDialogClass: 'modal-lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.folder = this.folder;
    modalRef.componentInstance.folderUpdate.subscribe((updated: FolderBoDtoNBK) => {
      this.folderService
        .updateFolder(
          localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
          updated
        )
        .subscribe(() => {
          this.folder = updated;
          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.FOLDER_UPDATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.md.reorderRecipe$.complete();
        });
    });
    modalRef.componentInstance.recipeDeleted.subscribe((v: any) => {
      this.folderService
        .removeRecipeFromFolder(this.folder.id!, v.id)
        .subscribe((data) => {
          const newRecipes = this.folder.recipes!.filter(
            (el) => el.id !== v.id
          );
          this.folder.recipes = [...newRecipes];
          modalRef.componentInstance.recipesInFolder = this.folder.recipes;

          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.RECIPE_DELETED_FOLDER'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          if (this.folder.recipes.length === 0) {
            this.onFolderDeleted.emit(this.folder);
            this.modalService.dismissAll();
          }
        });
    });
  }

  upperCaseTooltip(folder: FolderBoDtoNBK) {
    return upperCaseTooltip(folder.name);
  }

  disableTooltip(el: HTMLSpanElement) {
    return disableTooltip(el);
  }

  isMultilevel() {
    return this.folder.multilevel;
  }

}
