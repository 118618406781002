<ngx-datatable
  #appdatatable
  class="material"
  [columns]="_columns"
  [rows]="dataSource.currentItems"
  [limit]="dataSource.limit"
  [offset]="dataSource.page"
  [count]="dataSource.totalItems || 0"
  [externalPaging]="true"
  [externalSorting]="true"
  [scrollbarH]="true"
  columnMode="force"
  (page)="dataSource.onTablePage($event)"
  (sort)="dataSource.onTableSort($event)"
  [selectAllRowsOnPage]="false"
  (select)="elSelected.emit($event)"
  (activate)="onRowActivate($event)"
  [messages]="messages"
  [rowClass]="getRowClass"
  [selected]="selected"
  [groupRowsBy]="groupRowsBy!"
  [selectionType]="selectionType!"
  [ngClass]="className!"
>
<ngx-datatable-row-detail #tableRowdetail (toggle)="onDetailToggle($event)">
  <ng-template ngx-datatable-row-detail-template let-row="row">
    <div *ngIf="_rowDetailHandler">
      <ng-container *ngTemplateOutlet="_rowDetailHandler.template; context: { row: row }"></ng-container>
    </div>
  </ng-template>
</ngx-datatable-row-detail>
</ngx-datatable>

<div class="table-selection-indicator" *ngIf="_selectionHandler && _selectionHandler!.selectionSize() > 0" >
  <span>{{ 'GLOBAL.SELECTION_INDICATOR' | translate: 
      { selected: _selectionHandler!.selectionSize(), count: dataSource.totalItems } }}</span>
</div>

<ngb-pagination
  *ngIf="!hidePagination"
  [pageSize]="dataSource.limit"
  [page]="dataSource.page + 1"
  (pageChange)="dataSource.setPage($event - 1)"
  [collectionSize]="dataSource.totalItems || 0"
  [maxSize]="5"
></ngb-pagination>

<ng-template #checkboxTpl let-rowIndex="rowIndex" let-row="row">
  <div class="custom-control custom-checkbox">
    <input
      id="select-row-{{ rowIndex }}"
      type="checkbox"
      class="custom-control-input"
      [tooltip]="checkboxTooltip(row)"
      [disabled]="_disabledHandler && _disabledHandler.isDisabled(row)"
      [checked]="_selectionHandler!.isSelected(row)"
      (change)="_selectionHandler!.toggleItemSelected(row, $event)"
    />
    <label
      class="custom-control-label text-hide"
      for="select-row-{{ rowIndex }}"
    ></label>
  </div>
</ng-template>

<ng-template #checkBoxHeaderTemplate>
  <input 
    type="checkbox" 
    [disabled]="_disabledHandler && _disabledHandler.areAllDisabled(dataSource.currentItems)"
    [checked]="_selectionHandler!.areAllSelected(dataSource.currentItems)"
    (change)="_selectionHandler!.toggleAllItems(dataSource.currentItems, $event)"
  />
</ng-template>

<ng-template #expandTpl ngx-datatable-cell-template let-row="row" let-expanded="expanded">
  <i
    class="fa"
    [class.fa-chevron-right]="!expanded"
    [class.fa-chevron-down]="expanded"
    title="{{ 'GLOBAL.EXPAND_COLLAPSE_ROW' | translate }}"
    (click)="toggleExpandRow(row, expanded)"
    role="button"
  >
  </i>
</ng-template>

