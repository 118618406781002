var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { FiltrableDataSource } from '../FiltrableDataSource';
import { getFilterString } from './filterFunctions';
var ODataFiltrableDataSource = /** @class */function (_super) {
  __extends(ODataFiltrableDataSource, _super);
  function ODataFiltrableDataSource() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.isLoading = false;
    _this.error = undefined;
    return _this;
  }
  ODataFiltrableDataSource.prototype.loadData = function () {
    var _this = this;
    if (this.fetchFunction == null) {
      throw new Error('Error: fetchFunction not provided. To use ODataFiltrableDataSource you must provide a fetch function');
    }
    if (this._subscription !== undefined) {
      this._subscription.unsubscribe();
    }
    var sortString = this.sortBy ? this.sortBy.prop + " " + this.sortBy.dir : undefined;
    this.isLoading = true;
    this._subscription = this.fetchFunction(this.limit, this.page * this.limit, true, sortString, this._filterString, this._searchString).subscribe(function (res) {
      if ('result' in res) {
        _this.currentItems = res.result || [];
      } else if ('value' in res) {
        _this.currentItems = res.value || [];
      }
      _this._totalItems = res.count;
      _this.isLoading = false;
      _this.error = undefined;
    }, function (err) {
      _this.currentItems = [];
      _this._totalItems = 0;
      _this.isLoading = false;
      _this.error = err;
    });
  };
  ODataFiltrableDataSource.prototype.setCustomFilter = function (rule) {
    this._filters.custom = rule;
  };
  ODataFiltrableDataSource.prototype.removeCustomFilter = function () {
    this._filters.custom = undefined;
  };
  ODataFiltrableDataSource.prototype.setSearch = function (search) {
    this._searchString = search;
  };
  ODataFiltrableDataSource.prototype.removeSeach = function () {
    this._searchString = undefined;
  };
  ODataFiltrableDataSource.prototype.filterData = function () {
    this._filterString = getFilterString(this._filters);
    this.loadData();
  };
  ODataFiltrableDataSource.prototype.sortData = function () {
    this.loadData();
  };
  return ODataFiltrableDataSource;
}(FiltrableDataSource);
export { ODataFiltrableDataSource };