export function equal(value) {
  return {
    operator: 'equal',
    value: value
  };
}
export function notEqual(value) {
  return {
    operator: 'notEqual',
    value: value
  };
}
export function greaterThan(value) {
  return {
    operator: 'greaterThan',
    value: value
  };
}
export function greaterThanOrEqual(value) {
  return {
    operator: 'greaterThanOrEqual',
    value: value
  };
}
export function lessThan(value) {
  return {
    operator: 'lessThan',
    value: value
  };
}
export function lessThanOrEqual(value) {
  return {
    operator: 'lessThanOrEqual',
    value: value
  };
}
export function valueIn(value) {
  return {
    operator: 'in',
    value: value
  };
}
export function notValueIn(value) {
  return {
    operator: 'notIn',
    value: value
  };
}
export function contains(value) {
  return {
    operator: 'contains',
    value: value
  };
}
export function not(value) {
  return {
    operator: 'not',
    value: value
  };
}
export function and(first, second) {
  return {
    operator: 'and',
    value: [first, second]
  };
}
export function or(first, second) {
  return {
    operator: 'or',
    value: [first, second]
  };
}