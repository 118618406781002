/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AirventDtoNBK {
  type?: AirventDtoNBK.TypeEnum;
  time?: number;
}
export namespace AirventDtoNBK {
  export type TypeEnum = 'CLOSE' | 'OPEN' | 'PREOPENING';
  export const TypeEnum = {
    Close: 'CLOSE' as TypeEnum,
    Open: 'OPEN' as TypeEnum,
    Preopening: 'PREOPENING' as TypeEnum
  };
}
