/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FanDtoNBK {
  type?: FanDtoNBK.TypeEnum;
  speed?: number;
}
export namespace FanDtoNBK {
  export type TypeEnum = 'NORMAL' | 'ALTERNATING';
  export const TypeEnum = {
    Normal: 'NORMAL' as TypeEnum,
    Alternating: 'ALTERNATING' as TypeEnum
  };
}
