import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromUTCToLocale'
})
export class FromUTCToLocalePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): Date {
    let datestring = new Date(value);
    return this.isUTC(value) ? datestring : new Date(
      Date.UTC(
        datestring.getFullYear(),
        datestring.getMonth(),
        datestring.getDate(),
        datestring.getHours(),
        datestring.getMinutes(),
        datestring.getSeconds(),
        datestring.getMilliseconds()
      )
    );
  }

  isUTC(dateString: string | undefined | null) { 
    if ( dateString ) {
      if (dateString.endsWith('Z')) {
        return true;
      }
      
      const timezoneOffsetPattern = /([+-]\d{2}):\d{2}/;
      const match = dateString.match(timezoneOffsetPattern);
      if (match && match[0] === "+00:00") {
        return true;
      }
    } 

    return false;
  }  

}
