import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { DeviceDisplayDtoNBK, DeviceService, DisplayListBoDtoNBK } from 'src/app/api/nbk';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { DisabledItemsHandler } from 'src/app/core/disabled-items-handler';

@Component({
  selector: 'app-device-display-selection-modal',
  templateUrl: './device-display-selection-modal.component.html',
  styleUrls: ['./device-display-selection-modal.component.scss']
})
export class DeviceDisplaySelectionModal implements OnInit, OnDestroy {
  
  @Input() set display(value: DisplayListBoDtoNBK) {
    this._display = value;
    /**
     * contains also the devices for wich now the user is not owner
     * but must be kept for next association
     */
    this.associtedDevices = value.devices!.map( dev => dev.id! );
    this.modalTitle = value.name!;
  }

  @Output() 
  saveAssociation: EventEmitter<number[]> = new EventEmitter();

  _display: DisplayListBoDtoNBK;
  associtedDevices: number[];
  modalTitle: string;

  dataSource = new ODataFiltrableDataSource<DeviceDisplayDtoNBK>();
  columns: TableColumn[] = [];

  device$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();

  selectionHandler = new SelectedItemsHandler<DeviceDisplayDtoNBK>( 
    (item: DeviceDisplayDtoNBK)  => item.displayId === this._display.id, 'deviceId' );

  disabledHandler = new DisabledItemsHandler<DeviceDisplayDtoNBK>( 
      (item: DeviceDisplayDtoNBK) => this._display.constructor === true 
          && item.displayId === this._display.id , 'deviceId', 'MY_DISPLAY.ASSIGN_DEVICE_LOCKED' );

  deviceFilterGroup: UntypedFormGroup;

  constructor(
    private ts: TranslateService, 
    public activeModal: NgbActiveModal,
    private deviceService: DeviceService ) { }

  ngOnInit() {
    this.deviceFilterGroup = new UntypedFormGroup({
      deviceName: new UntypedFormControl('', []),
      deviceSerial: new UntypedFormControl('', []),
      displayName: new UntypedFormControl('', [])
    });

    this.columns = [
      {
        prop: 'deviceSerial',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SERIAL_NUMBER')
      },
      {
        prop: 'deviceName',
        name: this.ts.instant('DEVICES.DEVICE_CARD.NAME')
      },
      {
        prop: 'deviceModel',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODEL')
      },
      {
        prop: 'displayName',
        name: this.ts.instant('MY_DISPLAY.DISPLAY')
      }
    ];

    this.setdataSource();

    this.device$
    .pipe(
      takeUntil(this.unsubscribe$),
      tap(() => {
        return this.dataSource.loadData();
      })
    )
    .subscribe();

    this.refreshDevices();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refreshDevices() {
    this.dataSource.applyFilters();
    this.device$.next();
  }

  setdataSource() {
    this.dataSource.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.deviceService.getUserDeviceDisplay({
        deviceName: this.deviceFilterGroup.value.deviceName,
        deviceSerial: this.deviceFilterGroup.value.deviceSerial,
        displayName: this.deviceFilterGroup.value.displayName,
        cookingMode:  this._display.cookingMode!,
        deviceModel: this._display.deviceModel!,
        displayMode: 'MULTI_DISPLAY',
        top: top!,
        skip: skip!
      })
    };
    this.dataSource.limit = 7;
  }

  search() {
    this.refreshDevices();
  }

  clean() {
    this.deviceFilterGroup.reset();
    this.refreshDevices();
  }

  onConfirm() {
    this.saveAssociation.emit( this.selectionHandler.items.map( i => i.deviceId! ) );
    this.activeModal.close();
  }

}
