<div 
    class="folder-box" 
    (click)="openFolderDetail()"
    [ngClass]="{ 'ml-folder': isMultilevel() }"
>
    <p #el [tooltip]="upperCaseTooltip(folder)" [isDisabled]="disableTooltip(el)">
        {{ folder.name }}
    </p>
</div>

