/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PhaseActionBoDtoNBK {
  type?: PhaseActionBoDtoNBK.TypeEnum;
  customAction?: string;
}
export namespace PhaseActionBoDtoNBK {
  export type TypeEnum = 'MIX' | 'SPIN' | 'ADD' | 'WET' | 'COVER' | 'CUSTOM';
  export const TypeEnum = {
    Mix: 'MIX' as TypeEnum,
    Spin: 'SPIN' as TypeEnum,
    Add: 'ADD' as TypeEnum,
    Wet: 'WET' as TypeEnum,
    Cover: 'COVER' as TypeEnum,
    Custom: 'CUSTOM' as TypeEnum
  };
}
