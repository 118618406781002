export class DisabledItemsHandler<T> {

  private _items: Map<T[keyof T],T>;
  private _isDisabled: (item: T) => boolean;
  private _keyProperty: keyof T;
  private _message: string;

  constructor( isDisabled: (item: T) => boolean, keyProperty: keyof T, message: string ) {
    this._isDisabled = isDisabled;
    this._items = new Map();
    this._keyProperty = keyProperty;
    this._message = message;
  }

  get items(): T[] {
    return Array.from( this._items.values() );
  }

  private getPropertyValue<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
  }

  onLoadItems(items: T[]) {
    items.forEach( item => {
      if ( this._isDisabled(item) ) {
        this._items.set(this.getPropertyValue(item,this._keyProperty),item);
      }
    });
  }

  areAllDisabled(items: T[]) {
    let count = 0;
    items.forEach(item => {
      if ( this.isDisabled(item) ) {
        count++;
      }
    });
    return items.length === count;
  }
  
  isDisabled(item: T) {
    return this._items.has(this.getPropertyValue(item,this._keyProperty));
  }

  disabledSize() {
    return this._items.size;
  }

  disabledTooltipMessageKey (item: T) {
    return this.isDisabled(item) ? this._message : "";
  }

}
