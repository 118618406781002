import { Injectable } from "@angular/core";
import { RecipeBoDtoNBK } from "src/app/api/nbk";


@Injectable({
  providedIn: 'root'
})
export class RecipesHandler {

  constructor( ) {}

 
  showIconOnRecipe(recipe: RecipeBoDtoNBK) {
    return recipe.ics || recipe.multilevel;
  }

  getRecipeIcon(recipe: RecipeBoDtoNBK) {
    if ( recipe.ics ) {
      if ( recipe.cookingMode === 'COMBI' ) {
        return 'assets/images/CCS.png';
      } else if ( recipe.cookingMode === 'HSO' ) {
        return 'assets/images/HCS.png';
      } else if ( recipe.cookingMode === 'NABOO' ) {
        return recipe.multilevel ? 'assets/images/nabook/ML_ICS.png' 
                                 : 'assets/images/nabook/ICS.png';
      } else if ( recipe.cookingMode === 'NEO' ) {
        if ( recipe.multilevel ) {
          return 'assets/images/nabook/ML_CCS.png';
        } else {
          return recipe.phases && recipe.phases?.length > 0 && recipe.phases[0].cookingType === 'LEAVENING' 
              ? 'assets/images/nabook/ALS.png' : 'assets/images/nabook/CCS.png' 
        }
      }
    } else if ( recipe.multilevel ) {
      return 'assets/images/nabook/ML.png';
    }

    return '';
  }


}
