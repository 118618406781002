import { Pipe, PipeTransform } from '@angular/core';
import { fromSecondsToTimeString } from '../../core/utils';

@Pipe({
  name: 'timeRange'
})
export class TimeRangePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return fromSecondsToTimeString(value);
  }
}
