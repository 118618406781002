import { BehaviorSubject } from 'rxjs';
var FiltrableDataSource = /** @class */function () {
  function FiltrableDataSource() {
    this._currentItems$ = new BehaviorSubject([]);
    this._page = 0;
    this._limit = 10;
    this._filters = {
      fields: {}
    };
  }
  Object.defineProperty(FiltrableDataSource.prototype, "currentItems", {
    /**
     * Returns the array of items of the current page
     */
    get: function () {
      return this._currentItems$.value;
    },
    /**
     * Sets the array of items of the current page
     */
    set: function (val) {
      this._currentItems$.next(val);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(FiltrableDataSource.prototype, "currentItems$", {
    /**
     * Gets the array of items of the current page as an observable
     */
    get: function () {
      return this._currentItems$.asObservable();
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(FiltrableDataSource.prototype, "totalItems", {
    /**
     * Returns the total number of items (exluding pagination)
     */
    get: function () {
      return this._totalItems;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(FiltrableDataSource.prototype, "page", {
    /**
     * Current page
     */
    get: function () {
      return this._page;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(FiltrableDataSource.prototype, "limit", {
    /**
     * Number of items per page
     */
    get: function () {
      return this._limit;
    },
    /**
     * Sets the number of items per page
     */
    set: function (val) {
      this._limit = val;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(FiltrableDataSource.prototype, "sortBy", {
    /**
     * The field and direction of sorting
     */
    get: function () {
      return this._sortBy;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(FiltrableDataSource.prototype, "filters", {
    /**
     * Object containing field and custom filters applied to the data source
     */
    get: function () {
      return this._filters;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * Reloads the data in the table (asbstract method that should be implemented in subclasses)
   */
  FiltrableDataSource.prototype.loadData = function () {};
  /**
   * Sets a filter for a field of the object
   * @param field The field of the object to filter
   * @param filter Filter definition (use utility functions to create a filter, e.g equal('foo'))
   */
  FiltrableDataSource.prototype.setFilter = function (field, filter) {
    this._filters.fields[field] = filter;
  };
  /**
   * Removes a filter that was previously added to the data source
   * @param field The field of the previously added filter
   */
  FiltrableDataSource.prototype.removeFilter = function (field) {
    if (field in this._filters.fields) {
      delete this._filters.fields[field];
    }
  };
  /**
   * Apply the filters (resets page to 0 and reloads data)
   */
  FiltrableDataSource.prototype.applyFilters = function () {
    this._page = 0;
    this.filterData();
  };
  /**
   * Remove all fields filters applied
   */
  FiltrableDataSource.prototype.resetFilters = function () {
    this._filters.fields = {};
    this.applyFilters();
  };
  /**
   * Toggles a filter on a field. If the passed value is not undefined,
   * the filter will be immediately applied (without the need of calling applyFilter).
   * If the passed value is undefined, the filter will be immediately removed.
   *
   * @param field The field of the object on which the filter will be applied
   * @param operator The filter operator
   * @param value The value to filter for
   */
  FiltrableDataSource.prototype.toggleFilter = function (field, operator, value) {
    if (value == null) {
      this.removeFilter(field);
    } else {
      this.setFilter(field, {
        operator: operator,
        value: value
      });
    }
    this.applyFilters();
  };
  /**
   * Sets the current page of the table and reloads data
   * @param page The new page
   */
  FiltrableDataSource.prototype.setPage = function (page) {
    this._page = page;
    this.loadData();
  };
  /**
   * Sets the sorting colums
   * @param sortBy The sorting column and direction
   */
  FiltrableDataSource.prototype.setSort = function (sortBy) {
    this._sortBy = sortBy;
    this._page = 0;
    this.sortData();
  };
  /**
   * Helper method to paginate with ngx-datatable
   * @param event nxg-datatable page event
   */
  FiltrableDataSource.prototype.onTablePage = function (event) {
    this._limit = event.limit;
    this.setPage(event.offset);
  };
  /**
   * Helper method to sort with ngx-datatable
   * @param event ngx-datatable sort event
   */
  FiltrableDataSource.prototype.onTableSort = function (event) {
    this.setSort(event.sorts[0]);
  };
  /**
   * Method that implements the data filtering, to be implemented in the subclasses
   */
  FiltrableDataSource.prototype.filterData = function () {};
  /**
   * Method that implements the data sorting, to be implemented in the subclasses
   */
  FiltrableDataSource.prototype.sortData = function () {};
  return FiltrableDataSource;
}();
export { FiltrableDataSource };