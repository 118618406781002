/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AirventDtoNBK } from './airventDto';
import { AccessoryBoDtoNBK } from './accessoryBoDto';
import { PhaseBoDtoNBK } from './phaseBoDto';
import { StepBoDtoNBK } from './stepBoDto';
import { HoldingBoDtoNBK } from './holdingBoDto';
import { PreheatDtoNBK } from './preheatDto';
import { TagDtoNBK } from './tagDto';
import { ImageDtoNBK } from './imageDto';

export interface RecipeBoDtoNBK {
  id?: number;
  idUnique?: string;
  multilevel?: boolean;
  status?: RecipeBoDtoNBK.StatusEnum;
  creationLanguage?: string;
  version?: number;
  ics?: boolean;
  lastUpdate?: string;
  country?: string;
  preheat?: PreheatDtoNBK;
  cost?: number;
  deviceModel?: RecipeBoDtoNBK.DeviceModelEnum;
  kcal?: number;
  pieces?: number;
  time?: number;
  cookingMode?: RecipeBoDtoNBK.CookingModeEnum;
  typeId?: number;
  airvent?: AirventDtoNBK;
  finishing?: RecipeBoDtoNBK.FinishingEnum;
  drying?: boolean;
  name?: string;
  context?: string;
  previewStep?: string;
  previewIngredients?: string;
  previewAccessories?: string;
  tip?: string;
  icon?: ImageDtoNBK;
  holding?: HoldingBoDtoNBK;
  accessories?: Array<AccessoryBoDtoNBK>;
  tags?: Array<TagDtoNBK>;
  images?: Array<ImageDtoNBK>;
  steps?: Array<StepBoDtoNBK>;
  phases?: Array<PhaseBoDtoNBK>;
  user?: string;
}
export namespace RecipeBoDtoNBK {
  export type StatusEnum = 'ACTIVE' | 'DELETED';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Deleted: 'DELETED' as StatusEnum
  };
  export type DeviceModelEnum = 'ORACLE' | 'NABOO' | 'NEO';
  export const DeviceModelEnum = {
    Oracle: 'ORACLE' as DeviceModelEnum,
    Naboo: 'NABOO' as DeviceModelEnum,
    Neo: 'NEO' as DeviceModelEnum
  };
  export type CookingModeEnum = 'COMBI' | 'HSO' | 'NABOO' | 'NEO';
  export const CookingModeEnum = {
    Combi: 'COMBI' as CookingModeEnum,
    Hso: 'HSO' as CookingModeEnum,
    Naboo: 'NABOO' as CookingModeEnum,
    Neo: 'NEO' as CookingModeEnum
  };
  export type FinishingEnum = 'OFF' | 'SOFT' | 'MEDIUM' | 'HARD';
  export const FinishingEnum = {
    Off: 'OFF' as FinishingEnum,
    Soft: 'SOFT' as FinishingEnum,
    Medium: 'MEDIUM' as FinishingEnum,
    Hard: 'HARD' as FinishingEnum
  };
}
