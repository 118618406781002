import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DatatableComponent as NgxDatatableComponent,
  SelectionType,
  TableColumn
} from '@swimlane/ngx-datatable';
import {
  ODataFiltrableDataSource
} from 'filtrable-data-source';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { DisabledItemsHandler } from 'src/app/core/disabled-items-handler';

export interface RowDetail {
  template: TemplateRef<any>,
  loadDetail: (row: any) => any
}

@Component({
  selector: 'app-datatable-paginated',
  templateUrl: './datatable-paginated.component.html',
  styleUrls: ['./datatable-paginated.component.scss']
})
export class DatatablePaginatedComponent implements OnInit {
  @Input() groupRowsBy?: string;
  @Input() scrollable = true;
  @Input() hidePagination? = false;
  @Input() disabledLines?: boolean;
  @Input() dataSource: ODataFiltrableDataSource<any>;
  @Input() set selectionHandler(val: SelectedItemsHandler<any>) {
    this._selectionHandler = val;
    this.parseColumns();
  }
  @Input() set disabledHandler(val: DisabledItemsHandler<any>) {
    this._disabledHandler = val;
  }
  @Input() set columns(val: TableColumn[]) {
    this._columns = val;
  }
  @Input() selected: any[] = [];
  @Input() selectionType?: SelectionType = undefined;
  @Input() messages? = { emptyMessage: this.ts.instant('GLOBAL.NO_DATA') };
  @Input() className?: string;
  @Input() set rowDetailHandler(val: RowDetail) {
    this._rowDetailHandler = val;
    this.parseColumns();
  }

  @Output() elSelected = new EventEmitter<any>();
  @Output() elExpanded = new EventEmitter<any>();

  @ViewChild('checkboxTpl', { static: true }) 
  checkboxTpl: TemplateRef<any>;

  @ViewChild('checkBoxHeaderTemplate', { static: true })
  checkBoxHeaderTemplate: TemplateRef<HTMLElement>;

  @ViewChild('expandTpl', { static: true }) 
  expandTpl: TemplateRef<HTMLElement>;

  @ViewChild(NgxDatatableComponent, { static: true })
  ngxDatatable: NgxDatatableComponent;

  _selectionHandler?: SelectedItemsHandler<any>;
  _disabledHandler?: DisabledItemsHandler<any>;
  _columns: TableColumn[] = [];
  _rowDetailHandler?: RowDetail;


  constructor(private ts: TranslateService, private route: ActivatedRoute) {}

  async ngOnInit() {
    if (this._selectionHandler) {
      this._selectionHandler.itemsChanged$.subscribe(() => {
        // force ngx-datatable to execute change detection to reflect selection state
        this.selected = [...this.selected];
      });

      this.dataSource.currentItems$.subscribe(e => {
        this._selectionHandler?.onLoadItems(e);
        this._disabledHandler?.onLoadItems(e);
      });

    }

  }

  getRowClass = (row: any) => {
    if (this._selectionHandler) {
      return {
        active: this._selectionHandler.isSelected(row)
      };
    }
    return {};
  };

  private parseColumns() {
    if ( this._selectionHandler && !this.containsColumn('checkboxTpl') ) {
      this._columns.splice(0, 0, {
        $$id: 'checkboxTpl',
        width: 40,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        cellTemplate: this.checkboxTpl,
        headerTemplate: this.checkBoxHeaderTemplate,
      });
    }

    if ( this._rowDetailHandler && !this.containsColumn('expandTpl') ) {
      this._columns.splice(this.containsColumn('checkboxTpl') ? 1 : 0, 0, {
        $$id: 'expandTpl',
        width: 30,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        cellTemplate: this.expandTpl
      });
    }

  }

  private containsColumn ( columnId: string ) {
    return this._columns.some( c => c.$$id === columnId );
  }

  toggleExpandRow(row: any, expanded: any) {
    this.ngxDatatable.rowDetail.collapseAllRows();
    if (!expanded) {
      this._rowDetailHandler?.loadDetail(row);
      this.ngxDatatable.rowDetail.toggleExpandRow(row);
    }
  }

  onDetailToggle($event: any) {
    this.elExpanded.emit($event.value);
  }

  checkboxTooltip(row: any) {
    if ( this._disabledHandler ) {
      const message = this._disabledHandler.disabledTooltipMessageKey(row);
      return message ? this.ts.instant(message) : "";
    }
    return "";
  }

}
