<div class="modal-header">
  <h2>
    {{ modalTitle | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-3">
  <div class="row filter-box mx-2" [formGroup]="deviceFilterGroup">
    <div class="col-12 col-xl-10">
      <div class="row">
        <div class="col-12 col-xl-4 pt-3">
          <label class="custom-label w-100">
            {{ 'MY_DISPLAY.FILTER' | translate }}
            <input 
              type="text" 
              class="custom-input" 
              formControlName="deviceName" />
          </label>
        </div>
        <div class="col-12 col-xl-4 pt-3">
          <label class="custom-label w-100">
            {{ 'DEVICE.INFO.SERIAL' | translate }}
            <input 
              type="text" 
              class="custom-input" 
              formControlName="deviceSerial" />
          </label>
        </div>
        <div class="col-12 col-xl-4 pt-3">
          <label class="custom-label w-100">
            {{ 'MY_DISPLAY.DISPLAY' | translate }}
            <input 
              type="text" 
              class="custom-input" 
              formControlName="displayName" />
          </label>
        </div>
      </div>
    </div>
  
    <div class="col-12 col-xl-2">
      <div class="filter-button-box custom-filter-button-box">
        <button
          class="button-primary mb-2"
          (click)="search()"
        >
          <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
        </button>
        <button class="button-secondary mb-2" (click)="clean()">
          <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
        </button>
      </div>
    </div>
  </div>
  
  <app-datatable-paginated
    #table
    [dataSource]="dataSource"
    [columns]="columns"
    [selectionHandler]="selectionHandler"
    [disabledHandler]="disabledHandler"
  ></app-datatable-paginated>

</div>

<div class="note-text">
  <span><span class=".apex-text">*</span>{{ 'DEVICE.SELECTION_NOTE' | translate }}</span>
</div>
<div class="modal-footer">
  <button
    (click)="onConfirm()"
    class="button-primary"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}<span class=".apex-text">*</span>
  </button>
</div>
