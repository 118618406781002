/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RecipeBoDtoNBK } from './recipeBoDto';

export interface FolderBoDtoNBK {
  id?: number;
  multilevel?: boolean;
  preheat?: number;
  idUnique?: string;
  status?: FolderBoDtoNBK.StatusEnum;
  lastUpdate?: string;
  creationLanguage?: string;
  name?: string;
  recipes?: Array<RecipeBoDtoNBK>;
}
export namespace FolderBoDtoNBK {
  export type StatusEnum = 'ACTIVE' | 'DELETED';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Deleted: 'DELETED' as StatusEnum
  };
}
