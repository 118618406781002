// tslint:disable:ban-types
import fb from 'odata-filter-builder';
var oDataFilters = {
  equal: function (value, field, acc) {
    return acc.eq(field, value);
  },
  notEqual: function (value, field, acc) {
    return acc.ne(field, value);
  },
  greaterThan: function (value, field, acc) {
    return acc.gt(field, value);
  },
  greaterThanOrEqual: function (value, field, acc) {
    return acc.ge(field, value);
  },
  lessThan: function (value, field, acc) {
    return acc.lt(field, value);
  },
  lessThanOrEqual: function (value, field, acc) {
    return acc.le(field, value);
  },
  in: function (value, field, acc) {
    return acc.in(field, value);
  },
  notIn: function (value, field, acc) {
    return acc.notIn(field, value);
  },
  contains: function (value, field, acc) {
    return acc.contains(field, value);
  },
  not: function (value, field, acc) {
    return acc.not(applyFilterDefiniton(value, field, fb()));
  },
  and: function (value, field, acc) {
    var f = fb();
    applyFilterDefiniton(value[0], field, f);
    applyFilterDefiniton(value[1], field, f);
    return acc.and(f);
  },
  or: function (value, field, acc) {
    var f = fb('or');
    applyFilterDefiniton(value[0], field, f);
    applyFilterDefiniton(value[1], field, f);
    return acc.and(f);
  }
};
function applyFilterDefiniton(filter, field, acc) {
  var fn = oDataFilters[filter.operator];
  return fn(filter.value, field, acc);
}
function getFieldsFilterString(filters) {
  var fields = Object.keys(filters);
  if (fields.length === 0) {
    return undefined;
  }
  var result = fields.reduce(function (acc, field) {
    return applyFilterDefiniton(filters[field], field, acc);
  }, new fb());
  return result.toString();
}
export function getFilterString(filters) {
  var fieldFilterString = getFieldsFilterString(filters.fields);
  if (filters.custom === undefined) {
    return fieldFilterString;
  }
  var res = fb().and(filters.custom);
  if (fieldFilterString !== undefined) {
    res.and(fieldFilterString);
  }
  return res.toString();
}