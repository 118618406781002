import { getLocaleDirection, registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { KEY_LANGUAGE_STORAGE } from './utils';

export type TDirection = 'rtl' | 'ltr';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  themeDirection$: BehaviorSubject<TDirection> =
    new BehaviorSubject<TDirection>('ltr');

  lang$: BehaviorSubject<string> = new BehaviorSubject<string>('en');

  constructor(private ts: TranslateService) {}

  async initLanguage(): Promise<void> {
    const lang = this.getUserLanguage();
    this.ts.setDefaultLang(lang);
    await this.changeLanguage(lang);
  }

  async changeLanguage(lang: string = this.ts.getBrowserLang()!) {
    const langCode = lang.substring(0, 2);

    let module;
    try {
      module = await this.loadLocale(langCode);
    } catch (err) {
      console.log(err);
      module = await import(`@angular/common/locales/en`);
    }

    document.documentElement.lang = langCode;

    registerLocaleData(module.default);

    import(`../../assets/i18n/${langCode}.json`).then((res) => {
      this.ts.setTranslation(langCode, res);
      this.ts.use(langCode);
      localStorage.setItem(KEY_LANGUAGE_STORAGE, langCode);

      try {
        this.themeDirection$.next(getLocaleDirection(langCode));
      } catch (err) {
        this.themeDirection$.next(getLocaleDirection('en'));
      }

      this.lang$.next(langCode);
    });
  }

  private getUserLanguage(): string {
    return (
      localStorage.getItem(KEY_LANGUAGE_STORAGE) ||
      this.ts.getBrowserLang()!.substring(0, 2) ||
      'en'
    );
  }

  private loadLocale(locale: string) {
    switch (locale) {
      case 'ar': return import('@angular/common/locales/ar');
      case 'bg': return import('@angular/common/locales/bg');
      case 'cs': return import('@angular/common/locales/cs');
      case 'de': return import('@angular/common/locales/de');
      case 'da': return import('@angular/common/locales/da');
      case 'el': return import('@angular/common/locales/el');
      case 'en': return import('@angular/common/locales/en');
      case 'es': return import('@angular/common/locales/es');
      case 'fi': return import('@angular/common/locales/fi');
      case 'fr': return import('@angular/common/locales/fr');
      case 'hr': return import('@angular/common/locales/hr');
      case 'hu': return import('@angular/common/locales/hu');
      case 'it': return import('@angular/common/locales/it');
      case 'ko': return import('@angular/common/locales/ko');
      case 'lv': return import('@angular/common/locales/lv');
      case 'nl': return import('@angular/common/locales/nl');
      case 'nn': return import('@angular/common/locales/nn');
      case 'pl': return import('@angular/common/locales/pl');
      case 'pt': return import('@angular/common/locales/pt');
      case 'ro': return import('@angular/common/locales/ro');
      case 'ru': return import('@angular/common/locales/ru');
      case 'sl': return import('@angular/common/locales/sl');
      case 'sr': return import('@angular/common/locales/sr');
      case 'sv': return import('@angular/common/locales/sv');
      case 'tr': return import('@angular/common/locales/tr');
    }

    throw Error("not found import");
  }

}
