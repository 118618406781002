import { Subject } from 'rxjs';

export class SelectedItemsHandler<T> {

  itemsChanged$ = new Subject<void>();

  private _items: Map<T[keyof T],T>;
  private _isSelected: (item: T) => boolean;
  private _keyProperty: keyof T;

  constructor( isSelected: (item: T) => boolean, keyProperty: keyof T ) {
    this._isSelected = isSelected;
    this._items = new Map();
    this._keyProperty = keyProperty;
  }

  get items(): T[] {
    return Array.from( this._items.values() );
  }

  toggleAllItems(items: T[], event: Event ) {
    items.forEach( item => {
      this.toggleItemSelected(item,event);
    });
  }

  toggleItemSelected(item: T, event: Event) {
    const selected = (<HTMLInputElement>event.target).checked;
    const key = this.getPropertyValue(item,this._keyProperty);
    if (selected) {
      this._items.set(key, item);
    } else {
      this._items.delete(key);
    }

    this.itemsChanged$.next();
  }

  private getPropertyValue<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
  }

  onLoadItems(items: T[]) {
    items.forEach( item => {
      if ( this._isSelected(item) ) {
        this._items.set(this.getPropertyValue(item,this._keyProperty),item);
      }
    });
  }

  areAllSelected(items: T[]) {
    let count = 0;
    items.forEach(item => {
      if ( this.isSelected(item) ) {
        count++;
      }
    });
    return items.length === count;
  }
  
  isSelected(item: T) {
    return this._items.has(this.getPropertyValue(item,this._keyProperty));
  }

  selectionSize() {
    return this._items.size;
  }

}
