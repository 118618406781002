import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';
import { disableTooltip, upperCaseTooltip } from 'src/app/core/utils';
import { RecipesHandler } from '../recipes.handler';

@Component({
  selector: 'app-recipe-box',
  templateUrl: './recipe-box.component.html',
  styleUrls: ['./recipe-box.component.scss']
})
export class RecipeBoxComponent implements OnInit, AfterContentChecked {
  @Input() recipe: RecipeBoDtoNBK;
  @Input() customClass: string = '';
  @Input() canDelete: boolean = false;
  @Input() draggable: boolean = false;

  @Output() onDelete: EventEmitter<RecipeBoDtoNBK> = new EventEmitter();
  @Output() onDragRecipeStart: EventEmitter<DragEvent> = new EventEmitter();
  @Output() onDragRecipeEnd: EventEmitter<number> = new EventEmitter();
  @Output() onDragRecipe: EventEmitter<number> = new EventEmitter();

  constructor( private cdr: ChangeDetectorRef, private recipeHandler: RecipesHandler ) {}

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  deleteRecipe(recipe: RecipeBoDtoNBK) {
    this.onDelete.emit(recipe);
  }

  onDragStart(event: any) {
    this.onDragRecipeStart.emit(event);
  }

  onDragEnd(event: DragEvent) {
    this.onDragRecipeEnd.emit(this.recipe.id);
  }

  onDrag() {
    this.onDragRecipe.emit(this.recipe.id);
  }

  showIconOnRecipe(recipe: RecipeBoDtoNBK) {
    return this.recipeHandler.showIconOnRecipe(recipe);
  }

  getRecipeIcon(recipe: RecipeBoDtoNBK) {
    return this.recipeHandler.getRecipeIcon(recipe);
  }

  upperCaseTooltip(recipe: RecipeBoDtoNBK) {
    return upperCaseTooltip(recipe.name);
  }

  disableTooltip(el: HTMLSpanElement) {
    return disableTooltip(el);
  }

}
